import { createRouter,createWebHistory } from 'vue-router'
import landingPage from '@/views/LandingPage.vue'
import thanksPage from '@/components/ThanksPage.vue'
const routes = [
  {
    path: '/',
    name: 'home',
    component: landingPage
  },
  {
    path: '/thanks',
    name: 'thanks',
    component: thanksPage
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

export default router
