<template>      
  <router-view/>
</template>

<script >
</script>
<style>
@import './assets/media.css';
@import './assets/global.css';

</style>
